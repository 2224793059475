import Base from './Base.js';

class Images extends Base {
	/**
	 * Init
	 */
	ready() {
		this.initImages();
	}
	/**
	 * Replace images
	 */
	initImages() {
		// Set global CSS img opacity inherit
		document.body.style.setProperty('--wp-dark-mode-img-opacity', 1);

		this.replaceImages();
		this.replaceBackgroundImages();
		this.excludeFilterImages();

		WPDarkMode.onChange(() => {
			this.replaceImages();
			this.replaceBackgroundImages();
			this.excludeFilterImages();
		});
	}

	replaceImages() {
		const images = document.querySelectorAll('img');
		if (!images || !images.length) return;

		// Replace images.
		images.forEach((image) => {
			// Bail, if image src is not set.
			if (!image.src) return;

			let currentSrc = image.dataset.lightSrc || image.src;

			if (WPDarkMode.isActive) {
				const darkSrc = this.getDarkSrc(currentSrc);
				if (darkSrc) {
					image.dataset.lightSrc = currentSrc;
					image.dataset.darkSrc = darkSrc;
					image.src = darkSrc;
					image.srcset = darkSrc;
				}
			} else {
				const lightSrc = image.dataset.lightSrc;
				if (lightSrc) {
					image.src = lightSrc;
					image.srcset = lightSrc;
				}
			}

		});
	}

	// Background images
	replaceBackgroundImages() {
		// element having background image.
		var elementsWithBackground = document.querySelectorAll('*');

		// Iterate through the selected elements
		elementsWithBackground.forEach((element) => {
			// Check if the element has a background image
			var backgroundImage = window.getComputedStyle(element).getPropertyValue('background-image');
			
			if (backgroundImage !== 'none') {

				backgroundImage = backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
				// console.log("Found backgroundImage", element);

				// Add wp-dark-mode-ignore class.
				element.classList.add('wp-dark-mode-ignore');

				// Log('backgroundImage', backgroundImage)
				// change the background image to dark mode if dark mode is enabled
				if (WPDarkMode.isActive) {
					const darkSrc = this.getDarkSrc(backgroundImage);

					// console.log('darkSrc', darkSrc);

					if (darkSrc) {
						element.style.background = `url(${darkSrc})`;
						element.style.backgroundImage = `url(${darkSrc})`;

						// Set light src.
						element.dataset.lightSrc = backgroundImage;
						element.dataset.darkSrc = darkSrc;
					}
				} else {
					// change the background image to light mode if dark mode is disabled
					const lightSrc = element.dataset.lightSrc;
					if (lightSrc) {
						element.style.background = `url(${lightSrc})`;
						element.style.backgroundImage = `url(${lightSrc})`;
					}
				}
				
			}
		});
	}

	/**
	 * Get dark src
	 * 
	 * @param {string} src 
	 * @returns 
	 */
	getDarkSrc(src) {
		// Bail, if src is not set.
		if (!src) return;

		const replaceable_images = wp_dark_mode_json.options.image_replaces || false;
		if (!replaceable_images) return false

		const imagePair = replaceable_images.find((img) => {
			// remove query string from image src
			const srcWithoutQuery = src.split('?')[0];

			return img.light === srcWithoutQuery
		});

		if (!imagePair) return false

		return imagePair.dark;
	}

	/**
	 * Exclude low brightness images
	 */
	excludeFilterImages() {
		const lowBrightnessExcludes = wp_dark_mode_json.options.image_enabled_low_brightness ? wp_dark_mode_json.options.image_low_brightness_excludes : false;
		const lowGrayscaleExcludes = wp_dark_mode_json.options.image_enabled_low_grayscale ? wp_dark_mode_json.options.image_low_grayscale_excludes : false

		const images = document.querySelectorAll('img');

		if( images && images.length ) {
			images.forEach((image) => {
				const src = image.src;
	
				if( lowBrightnessExcludes && lowBrightnessExcludes.includes(src) ) {
					// Set filter to 0.
					image.style.setProperty('--wp-dark-mode-img-brightness', '100%');
				}
	
				if( lowGrayscaleExcludes && lowGrayscaleExcludes.includes(src) ) {
					// Set filter to 0.
					image.style.setProperty('--wp-dark-mode-img-grayscale', '0%');
				}
			});
		}
		
	}

}

export default new Images();