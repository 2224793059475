import DarkModeApp from '@common/app.js';
import DarkModeSwitch from '@common/Switch.js';
import { Log } from '@common/Utility.js'; 
/**
 * Initialize the app
 *
 * These classes can be initialized only once to avoid conflicts
 */

// document.addEventListener('readystatechange', () => {
    // if (document.readyState === 'complete') {
        window.Log = Log;

        window.WPDarkModeApp = DarkModeApp.init();
        console.log('Dark Mode App Initialized');
        
        // Initialize the switch.
        DarkModeSwitch.init()
//     }
// });

